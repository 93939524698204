import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';

import MainNavbar from './components/navbar';
import List from '@mui/material/List';
import CohortList from './components/cohortList';
import MemberList from './components/memberList';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function App() {
  const isMobile = useMediaQuery('(max-width: 600px)');

  // Detect system preference for dark mode
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Define light and dark themes
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#000', // customize as needed
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff', // customize as needed
      },
    },
  });

  // Apply the correct theme based on system preference
  const theme = prefersDarkMode ? darkTheme : lightTheme;

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* CSSBaseline is needed to apply theme changes */}
      <CssBaseline />
      <div className="App">
        <MainNavbar />
        <div style={{ maxWidth: '1350px', margin: '0 auto' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5" fontWeight="bold" sx={{ py: 2, px: 2 }}>
              Coffee Chat Dashboard
            </Typography>
          </div>

          <div>
            <Box sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'center' : 'flex-start',
              justifyContent: 'space-between',
            }}>
              <Box sx={{ width: isMobile ? '100%' : '65%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2 }}>
                  <Tabs value={tabValue} onChange={handleTabChange} sx={{
                    '& .Mui-selected': { color: theme.palette.primary.main },
                    '& .MuiTabs-indicator': { backgroundColor: theme.palette.primary.main }
                  }}>
                    <Tab label="Cohort" />
                    <Tab label="Members" />
                  </Tabs>
                </Box>

                <div style={{ height: '10px' }}></div>

                <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#f5f5f5', borderRadius: '4px' }}>
                  <Typography sx={{ flex: 1, paddingLeft: '13px' }}>Name</Typography>
                  <Typography sx={{ width: '100px', textAlign: 'center', mr: 2 }}>Coffee Chats</Typography>
                </Box>

                <List sx={{ pt: 2, px: 2 }}>
                  {tabValue === 0 ? <CohortList theme={theme} /> : <MemberList theme={theme} />}
                </List>
              </Box>

              <Box sx={{ width: isMobile ? '100%' : '35%', padding: isMobile ? '15px' : '0 15px', mb: isMobile ? 2 : 0 }}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      How It Works
                    </Typography>
                    <Typography variant="body1" color="text.secondary" >
                      Whenever you have a coffee chat, share it the same way you would post a snipe!
                      <br />
                      <br />
                      Click on a name to see who they've chatted with.
                      <br />
                      <br />
                      Cohort members are required to have at least <strong>25 coffee chats</strong> by the end of the semester.
                      <br />
                      <br />
                      Had a coffee chat, but it's not showing up here? Message <strong>Kevin Wang</strong> on Slack!
                      <br />
                      <br />
                      Happy coffee chatting! ☕️
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </div>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', py: 2 }}>
            <Typography variant="caption">
              Made with ❤️ by <span style={{ textDecoration: 'underline' }}><a href="http://www.kevindw.dev">Kevin Wang</a></span> for the V1 Community
            </Typography>
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
