import { useState } from 'react';
import * as React from 'react';
import { createClient } from '@supabase/supabase-js';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Create a single supabase client for interacting with your database
const supabase = createClient('https://avempzothnvvhwfqkvws.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF2ZW1wem90aG52dmh3ZnFrdndzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTMxMDI0MzMsImV4cCI6MjAwODY3ODQzM30.IwnbTeBZEhfclmEPs_J0mnOX4-nvEv9ab36awWrFvuo')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// get data from the database
const { data } = await supabase
    .from('coffee_chats')
    .select('name, completed, members_chatted')

console.log(data);

const cohortNames = [
    'Amy Liu',
    'Arhan Kaul',
    'Aryan Shah',
    'Benjamin Antonow',
    'Casey Feng',
    'Diego Paredes',
    'Jeffery Wu',
    'Joshua Lee',
    'Kritika Singh',
    'Maya Malik',
    'Pari Gulati',
    'Phoenix Sheppard',
    'Riya Saha',
    'Sanjit Vijay'
]

// order the data by the name in alphabetical order
let sortedData = data.sort((a, b) => (a.name > b.name) ? 1 : -1);

// only show the data for the cohort members
sortedData = sortedData.filter((person) => cohortNames.includes(person.name));

console.log(sortedData);

export default function CohortList({ theme }) {
    const [open, setOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);

    const handleClickOpen = (person) => {
        setSelectedPerson(person);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedPerson(null);
    };

    const listItems = sortedData.map((person) => (
        <Card sx={{
            mb: 1,
            transition: 'background-color 0.3s ease',
            '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#e0e0e0', // Adjust hover color for dark mode
                cursor: 'pointer',
            },
        }} key={person.name} onClick={() => handleClickOpen(person)}>
            <CardContent style={{ padding: 0, margin: 0 }}>
                <ListItem sx={{ p: 2 }}>
                    <ListItemText primary={person.name} />
                    <ListItemIcon>
                        <ListItemText primary={person.completed} />
                    </ListItemIcon>
                </ListItem>
            </CardContent>
        </Card>
    ));

    return (
        <div>
            <ul>{listItems}</ul>

            {/* Modal */}
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{selectedPerson?.name}'s Coffee Chats</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {selectedPerson && (
                        <Box gutterBottom>
                            <List>
                                {selectedPerson.members_chatted.map((member, index) => (
                                    <div key={member}>
                                        <ListItem
                                            sx={{
                                                paddingLeft: '5px',
                                                fontSize: '1.1rem',
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            {member}
                                        </ListItem>
                                        {index < selectedPerson.members_chatted.length - 1 && <Divider />}
                                    </div>
                                ))}
                            </List>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
